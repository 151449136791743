import PropTypes from 'prop-types';

import Button from 'components/Button';

import classes from 'styles/components/Hero.module.scss';

import { LOGIN } from 'utils/constants/urls';

const goToLogin = () => {
  window.location = LOGIN;
};

const Hero = ({ children, ctaButtonText, ctaOnClick, imageUrl, showLogin }) => (
  <div className={classes.root}>
    <div className={classes.left}>
      <div>
        {children}

        <div className={classes.buttons}>
          <Button filled color="white" onClick={ctaOnClick} fullWidth={!showLogin}>
            {ctaButtonText}
          </Button>

          {showLogin && (
            <>
              <div className={classes.buttonSpacer} />
              <Button color="white" onClick={goToLogin}>
                Login
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
    <div style={{ backgroundImage: `url(${imageUrl})` }} className={classes.right} alt="" />
  </div>
);

Hero.propTypes = {
  children: PropTypes.node.isRequired,
  ctaButtonText: PropTypes.string,
  imageUrl: PropTypes.string,
  ctaOnClick: PropTypes.func,
  showLogin: PropTypes.bool,
};

Hero.defaultProps = {
  ctaButtonText: "Let's Get Started",
  imageUrl: '/stock-laptop.png',
  ctaOnClick: () => null,
  showLogin: false,
};

export default Hero;
