import PropTypes from 'prop-types';
import classNames from 'classnames';

import Content from 'components/Content';

import classes from 'styles/components/Line.module.scss';

const Line = ({ secondary }) => {
  if (secondary) {
    return (
      <Content>
        <div className={classNames(classes.root, classes.secondary)} />
      </Content>
    );
  }

  return <div className={classes.root} />;
};

Line.propTypes = {
  secondary: PropTypes.bool,
};

Line.defaultProps = {
  secondary: false,
};

export default Line;
