import PropTypes from 'prop-types';

import classes from 'styles/components/ListItem.module.scss';

import CheckIcon from 'icons/check.svg';

const ListItem = ({ children }) => (
  <div className={classes.root}>
    <CheckIcon className={classes.icon} />

    {children}
  </div>
);

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ListItem;
