import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/Button';

import classes from 'styles/components/OutlinedBox.module.scss';

const OutlinedBox = ({ onClick, buttonText, hideButton, shrinkButton, children }) => (
  <div className={classes.root}>
    <div className={classes.inner}>
      <div className={classes.content}>{children}</div>

      {!hideButton && (
        <Button
          color="blue"
          filled
          onClick={onClick}
          className={classNames(classes.button, { [classes.noshrink]: !shrinkButton })}
        >
          {buttonText}
        </Button>
      )}
    </div>
  </div>
);

OutlinedBox.propTypes = {
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  hideButton: PropTypes.bool,
  shrinkButton: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

OutlinedBox.defaultProps = {
  onClick: () => null,
  buttonText: "Let's Get Started",
  hideButton: false,
  shrinkButton: false,
};

export default OutlinedBox;
