const links = [
  {
    text: 'Privacy Policy',
    href: 'https://www.metlife.com/about-us/privacy-policy/online-privacy-policy/',
  },
  { text: 'Terms & Conditions', href: 'https://www.metlife.com/about-us/terms-and-conditions/' },
  { text: 'Privacy Request', href: 'https://online.metlife.com/edge/web/public/privacy' },
];

export default links;
