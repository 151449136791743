import Link from 'next/link';

import Content from 'components/Content';
import Spacer from 'components/Spacer';
import Typography from 'components/Typography';

import classes from 'styles/components/Footer.module.scss';

import Logo from 'public/images/metlife-logo.svg';
import Phone from 'icons/phone.svg';
import Mail from 'icons/mail.svg';

import { CAREERS } from 'utils/constants/urls';

import links from './links';

const FooterContent = () => (
  <Content>
    <Spacer v={80} />
    <div className={classes.root}>
      <Logo alt="MetLife logo" className={classes.logo} />

      <div>
        <div className={classes.root}>
          <Typography variant="calltoaction2">
            <Phone className={classes.icon} />
            <Spacer h={8} inline />
            <a href="tel:+18332144172">833-214-4172</a>
          </Typography>

          <Typography variant="calltoaction2">
            <Mail className={classes.icon} />
            <Spacer h={8} inline />
            <a href="mailto:clientservice@legalplans.com">clientservice@legalplans.com</a>
          </Typography>

          <Link href="/attorney">
            <a>
              <Typography variant="calltoaction2" className={classes.blueLink}>
                For Attorneys
              </Typography>
            </a>
          </Link>

          <Link href={CAREERS}>
            <a>
              <Typography variant="calltoaction2" className={classes.blueLink}>
                Careers
              </Typography>
            </a>
          </Link>

          {/* <Link href="/">
            <a>
              <Typography variant="h5" bold className={classes.blueLink}>
                Get Proposal
              </Typography>
            </a>
          </Link> */}
        </div>

        <Spacer v={24} />

        <div className={classes.root}>
          {links.map(({ text, href }) => (
            <a href={href} key={href}>
              <Typography variant="calltoaction2" className={classes.plainLink}>
                {text}
              </Typography>
            </a>
          ))}
        </div>

        <Spacer v={24} />

        <div className={classes.root}>
          <Typography variant="calltoaction2" bold={false}>
            &copy; {new Date().getFullYear()} MetLife Services and Solutions, LLC, New York, NY
            10166 - All Rights Reserved.
          </Typography>
        </div>

        <Spacer v={24} />
      </div>
    </div>

    <Spacer v={16} />
  </Content>
);

const Footer = () => (
  <footer>
    <FooterContent />
  </footer>
);

export default Footer;
