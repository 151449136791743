import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from 'styles/components/SideBySide.module.scss';

const SideBySide = ({ children, center }) => {
  const className = classNames(classes.root, { [classes.center]: center });
  return <div className={className}>{children}</div>;
};

SideBySide.propTypes = {
  children: PropTypes.node.isRequired,
  center: PropTypes.bool,
};

SideBySide.defaultProps = {
  center: false,
};

export default SideBySide;
